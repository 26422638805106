import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { 
  Box, 
  Flex, 
  FormControl,
  FormLabel, 
  Image, 
  Spacer,
  Select, 
  Heading, 
  Text 
} from '@chakra-ui/react'
import Layout from '../components/Layout'

const Editions = () => {
  const [editions, setEditions] = useState([])
  const [selectedEdition, setSelectedEdition] = useState(null)

  const handleSelection = (e) => {
    setSelectedEdition(editions[e.target.value])
  }

  useEffect(() => {
    fetch(`${process.env.GATSBY_REST_URL}/editions`)
    .then(response => response.json())
    .then(data => {
      setEditions(data);
      setSelectedEdition(data[0])
    });
  }, [])
  
  return (
    <Box 
      pt={[6, 12, 24]}
      px={[6, 12, 16]}
    >
        <Box mb="8">
          <Heading mt="0" mb="4" size="4xl" as="h1">Editions</Heading>
          <Text>Here's the page where we archive all the previous editions with their chapters</Text>
        </Box>
        <FormControl w={['100%', '30%']} mb="8">
          <FormLabel fontSize="xs">Edition Number</FormLabel>
          <Select 
            placeholder="Select an edition"
            onChange={handleSelection}>
            {editions.map((edition, idx) => 
              <option key={edition.id} value={idx}>{edition.title}</option>  
            )}
          </Select>
        </FormControl>
        {selectedEdition && (
          <>
            <Flex alignItems={['none', 'flex-end']} direction={['column', 'row']} mb="8">
              <Image src={selectedEdition?.cover_image?.image?.sizes?.medium_large} w={['100%', '33%']}/>
              <Box flex="1" ml={[0, 8]}>
                <Text>{selectedEdition.edition_number}</Text>
                <Text as="h2" my="4" fontSize={['3xl', '6xl']}>{selectedEdition.main_story_title}</Text>
                <Text>{selectedEdition.posts?.length} chapters</Text>
              </Box>
            </Flex>
            <Box>
              <Text fontSize="md" borderBottom="1px solid" lineHeight="10">Chapter Title</Text>
              {selectedEdition?.posts.map((post, idx) => 
                <Flex 
                  key={post.id}
                  py="4" 
                  direction={['column', 'row']} 
                  alignItems={['none', 'center']}>
                  <Image 
                    src={post.thumbnail_image} 
                    borderRadius="50%"
                    background="gray.700"
                    height="20"
                    width="20"
                  />
                  <Box ml={[0, 8]} mb={[6, 0]}>
                    <Text fontSize="2xl" fontWeight="bold">Chapter {idx+1}: {post.title}</Text>
                    <Text fontSize="sm">by {post.author}</Text>
                  </Box>
                  <Spacer/>
                  <Link to={`/${selectedEdition.slug}/${post.slug}`}>
                    <Text>Read more</Text>
                  </Link>
                </Flex>  
              )}
            </Box>
          </>
        )}
    </Box>
  )
}

Editions.Layout = Layout
export default Editions
